<template>
  <div>
    <el-dialog
    title="流程记录"
    :visible.sync="dialogVisible2"
    width="70%"
    :before-close="handleClose2">
    <table-page
        :data="tableData"
        :columns="columns"
        :border="true"
        :pagination="false"
      >
      </table-page>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose2">取 消</el-button>
      <el-button type="primary" @click="handleClose2">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>

import TablePage from "@/components/table/table-page";
import { ProcessRecordColumn } from "./tableColumn";
import { selectHistoryTaskByProcessInstanceId } from "@/api/spgl";
import { log } from "console";
export default {
  components: {
    TablePage,
  },
  props: {
    // 定义接收父组件传递的数据
    rowData: {
      type: Object,
      required: false,
    }
  },
  data(){
    return{
      dialogVisible2:true,
      tableData: [
        {
          orderType:'237288743',
          cropCode:'资源资产流转审批',
          landArea:'村集体审批',
          estimatePrice:'发起人',
          orderState:'任务创建时间',
          createTime:'流程进度',

        },
        {
          orderType:'237288743',
          cropCode:'资源资产流转审批',
          landArea:'村集体审批',
          estimatePrice:'发起人',
          orderState:'任务创建时间',
          createTime:'流程进度',

        },
        {
          orderType:'237288743',
          cropCode:'资源资产流转审批',
          landArea:'村集体审批',
          estimatePrice:'发起人',
          orderState:'任务创建时间',
          createTime:'流程进度',

        },
        {
          orderType:'237288743',
          cropCode:'资源资产流转审批',
          landArea:'村集体审批',
          estimatePrice:'发起人',
          orderState:'任务创建时间',
          createTime:'流程进度',

        },

      ],
      // query: {
      //   pnum: 1,
      //   psize: 10,
      // },
      columns: ProcessRecordColumn,

  }},
  mounted(){
    this.jilu()
  },
  methods:{
    handleClose2() {
      this.$emit("closeDialog2", false);
    },
    async jilu(e){
      console.log(e);
      
      let res = await selectHistoryTaskByProcessInstanceId({
        processInstanceId:e.instanceProcessId
      })
      this.tableData = res
    }
  }
};
</script>
<style lang="less" scoped>
span{
  cursor: pointer; /* 鼠标悬停时变成小手 */
}

</style>


